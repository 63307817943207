import { alertController } from '@ionic/vue';

interface ValidatorOptions {
	validator: ValidatorFunction;
	element: HTMLInputElement | string;
	validatorParams?: any;
	optional?: boolean;
	nextElement?: HTMLElement | string;
	fieldName?: string;
}

interface ValidatorState extends ValidatorOptions {

	resolvedElement: HTMLInputElement;
	resolvedValidator: ValidatorFunction;
	previousContent: string;
	resolvedNextElement?: HTMLElement;
	validatorHelper: Function;
}

type ValidatorFunction = (validatorParams: any, currentValue: string, fieldName?: string) => ValidationResult;

interface ValidationResult {
	ok: boolean;
	isFull: boolean;
	formatted: string;
	reason: string;
}

class Validator {

	public options: ValidatorState;
	public ok: boolean;
	public result: ValidationResult;

	constructor(o: ValidatorOptions) {

		let vFinal: ValidatorFunction;
		if (o.optional) {
			vFinal = function (a, b, c) {
				return b === '' ? { ok: true, isFull: false, formatted: '', reason: '' } : o.validator(a, b, c)
			}
		} else {
			vFinal = o.validator;
		}


		this.options = {
			resolvedValidator: vFinal,
			previousContent: '',
			resolvedElement: document.querySelector('body') as any,
			validatorHelper: () => false,
			...o,
		};

		this.result = {
			ok: false,
			isFull: false,
			formatted: '',
			reason: 'Inicializando...'
		};

		this.ok = false;

		this.bind();
		return this;

	}

	public refresh(): void {
		this.options.validatorHelper(true, false)({});
	}

	private setResult(r: ValidationResult): void {
		this.result = r;
		this.ok = r.ok;
	}

	public bind(): void {
		let ok = true;

		if (typeof this.options.element === 'string') {
			const re = document.querySelector(this.options.element);
			if (re && ('value' in re)) {
				this.options.resolvedElement = re;
			} else {
				ok = false;
			}
		} else {
			this.options.resolvedElement = this.options.element;
			if (!this.options.element) ok = false;
		}

		if (!ok) {
			this.setResult({
				ok: false,
				isFull: false,
				formatted: '',
				reason: 'Error de configuración'
			});
			return;
		}

		const cl = this.options.resolvedElement.classList;
		if (cl.contains('validationApplied')) {
			return;
		} else {
			cl.add('validationApplied');
		}

		// Setup

		// Binding a keyup y blur

		const validatorHelper = (alwaysFlag: boolean, nextIfFull: boolean) => {
			return (ev: Event) => {
				const e = this.options.resolvedElement as HTMLInputElement;
				const valorOrig = e.value as string;
				this.setResult(this.options.resolvedValidator(this.options.validatorParams, valorOrig, this.options.fieldName));
				if (valorOrig !== this.result.formatted) {
					e.value = this.result.formatted;

					if (this.result.formatted === '') {
						// Fix Android shenanigans
						const el = e.querySelector('input');
						if (el && el.value != '') el.value = '';
					}
				}
				if (alwaysFlag || this.ok || (ev as any).key === 'Enter') this.flag();

				if (this.options.resolvedNextElement) {

					// Hay siguiente elemento. Saltar si:
					//	a) Está completo, se ha modificado el valor y nextIfFull está habilitado; 
					//	b) El valor es correcto y se pulsó Enter.

					if ((this.result.isFull && nextIfFull && this.options.previousContent !== e.value)
						|| ((ev as any).key === 'Enter' && this.result.ok)) {

						// setFocus es una ampliación de Ionic
						const nextEl = this.options.resolvedNextElement as any;
						if (nextEl.setFocus)
							nextEl.setFocus();
						else
							nextEl.focus();
					}
				}

				this.options.previousContent = e.value;
			}
		}

		this.options.validatorHelper = validatorHelper;
		const typingValidator = validatorHelper(false, true);
		const focusOutValidator = validatorHelper(true, false);

		this.options.resolvedElement.addEventListener('keyup', typingValidator);
		this.options.resolvedElement.addEventListener('blur', focusOutValidator);
		this.options.resolvedElement.addEventListener('ionBlur', focusOutValidator);

		// Lo siguiente lo metemos en un setTimeout para que el DOM se estabilice

		setTimeout(() => {

			// Verificar que el valor actual del elemento es correcto y anular en caso contrario

			const va = this.options.resolvedElement.value;

			this.setResult(this.options.resolvedValidator(this.options.validatorParams, va, this.options.fieldName));
			this.options.previousContent = this.options.resolvedElement.value = this.result.formatted;
			this.ok = this.result.ok;
			if (this.result.formatted !== '') this.flag();

			// Resolver el valor siguiente

			if (this.options.nextElement) {
				const nextEl: any = (typeof this.options.nextElement === 'string')
					? document.querySelector(this.options.nextElement)
					: this.options.nextElement;

				if (nextEl) {
					this.options.resolvedNextElement = nextEl;
				}
			}

			// Añadir blur al elemento nativo

			const native = this.options.resolvedElement.querySelector('input');
			if (native)
				native.addEventListener('blur', focusOutValidator);
		}, 300);

	}

	public flag(remove?: boolean): void {
		// Identificar el elemento a mostrar/ocultar

		const el1 = (this.options.resolvedElement as HTMLInputElement)
			.closest('.inputWrapper, ion-item');
		const el = el1 ? el1.querySelector('.validationFailed') : null;

		if (!el) return;

		if (remove || this.ok) {
			el.classList.remove('validationFailedShow');
		} else {
			el.classList.add('validationFailedShow');
			if (typeof (el as any).title === 'string') {
				(el as any).title = this.result.reason;
				const a = el.querySelector('ion-icon');
				if (a && a.shadowRoot) {
					const b = a.shadowRoot.querySelector('title');
					if (b) b.remove();
				}
			}
		}
	}


}

function validateRegexp(v: any, cv: string, fieldName?: string): ValidationResult {
	if (cv.match(v.regexp)) {
		return {
			ok: true,
			isFull: !!v.full,
			formatted: cv,
			reason: ''
		}
	}

	return {
		ok: false,
		isFull: false,
		formatted: cv,
		reason: v.noMatch || (fieldName ? 'El valor para el campo ' + fieldName + ' no es válido.' : 'Este valor no es válido.')
	}
}

function validateEmail(v: any, cv: string, fieldName?: string): ValidationResult {

	const mailRegexp = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';

	return validateRegexp({ regexp: new RegExp(mailRegexp), noMatch: fieldName ? 'El email ' + fieldName + ' no es válido.' : 'El email no es válido.' }, cv);
}


function validateDuiPhone(isDui: boolean, v: any, cv: string, fieldName?: string): ValidationResult {

	// Validates DUIs (8 digits + - + 1 digit) and phones (4 digits + - + 4 digits, starting
	// with 2,6,7,8)

	const expectedLengths = isDui ? [8, 1] : [4, 4];

	// Split on hyphens

	let pieces = cv.split('-');

	if (pieces.length > 2) { // Acumular todo lo que sobre en la segunda pieza
		const p0 = pieces.shift();
		const p1 = pieces.join();
		pieces = [p0 as string, p1];
	}

	let hasHyphen = (pieces.length > 1);

	// Strip everything but numbers from the first two pieces

	pieces[0] = pieces[0].replace(/[^0-9]/g, '');
	if (hasHyphen)
		pieces[1] = pieces[1].replace(/[^0-9]/g, '');

	// Pad first piece if necessary, for DUIs

	if (hasHyphen && pieces[0].length < 8 && isDui) {
		pieces[0] = ('00000000' + pieces[0]).substr(-8)
	}

	// If phone, check that the first number is 2, 6, 7 or 8

	if (!isDui && pieces[0].length && !pieces[0].match(/^[2678]/)) {
		pieces = ['']; // no, delete everything
		hasHyphen = false;
	}

	// If the length of the first piece is still smaller than
	// the expected length and there is a hyphen, coalesce everything in the first piece

	if (hasHyphen && pieces[0].length < expectedLengths[0]) {
		pieces = [pieces[0] + pieces[1]];
		hasHyphen = false;
	}

	// Check for overflow from the first piece to the second

	if (!hasHyphen && pieces[0].length >= expectedLengths[0]) {
		pieces[1] = pieces[0].substr(expectedLengths[0]);
		pieces[0] = pieces[0].substr(0, expectedLengths[0]);
		hasHyphen = true;
	}

	// Trim the second part to the maximum length

	if (hasHyphen)
		pieces[1] = pieces[1].substr(0, expectedLengths[1]);

	// Rebuild value

	const nextVal = pieces[0] + ((hasHyphen && pieces[1].length) ? ('-' + pieces[1]) : '');

	const isOK = hasHyphen && pieces[1].length == expectedLengths[1];

	return {
		ok: isOK,
		formatted: nextVal,
		isFull: isOK,
		reason: isOK ? '' : isDui ?
			('El ' + (fieldName ? 'campo ' + fieldName : 'DUI') + ' debe ser del tipo 12345678-9.')
			: ('El ' + (fieldName ? 'campo ' + fieldName : 'teléfono') + ' debe tener 8 dígitos y empezar por 2, 6, 7 u 8.')
	}


}

function validateDUI(v: any, cv: string, fieldName?: string): ValidationResult {
	return validateDuiPhone(true, v, cv, fieldName);
}
function validatePhone(v: any, cv: string, fieldName?: string): ValidationResult {
	return validateDuiPhone(false, v, cv, fieldName);
}

class ValidatorSet {

	private validators: Validator[];

	constructor(v: ValidatorOptions[]) {

		this.validators = [];

		for (const i of v) {
			this.validators.push(new Validator(i));
		}

	}

	public check() {
		const msg = [];
		for (const i of this.validators) {
			i.refresh();
			if (i.ok) continue;
			msg.push(i.result.reason);
		}

		return {
			ok: !msg.length,
			reason: msg.join(' ')
		}
	}

	public flag() {
		for (const i of this.validators) {
			i.flag();
		}
	}

	public bind() {
		for (const i of this.validators) {
			i.bind();
		}
	}

	public async checkWithAlert() {
		const v = this.check();
		if (!v.ok) {
			const alert = await alertController.create({
				header: "Error",
				message: v.reason,
				buttons: ['OK']
			});
			alert.present();
			this.flag();
		}
		return v.ok;
	}

}

export { Validator, ValidatorSet, validateDUI, validateEmail, validatePhone, validateRegexp };
export default ValidatorSet;